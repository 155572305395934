import {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    getNoAuthRequest,
    deleteRequest,
} from "@/libs/axios";

// 查询考试成绩
export function getgrade(params) {
    return getRequest("/yethan/examBank/getExam", params);
}
// 查询考试成绩库记录
export function getLibraryList(params) {
    return getRequest("/yethan/examBankRecord/List", params);
}
// 查询考试成绩库记录（单条）
export function getLibraryInfo(params) {
    return getRequest("/yethan/examBankRecord/"+ params);
}
// 成绩查询信息提交
export function cjSearch(params) {
    return getNoAuthRequest('/yethan/examBank/getExamByCode', params);
}
// 成绩表模板样式详情
export function getTemplateInfo(mid) {
    return getRequest("/yethan/print/printTemplate/" + mid);
  }


//成绩
//成绩复核列表
export function examReviewList(params) {
    return getRequest("/yethan/examReview/list", params);
}
//撤销成绩复核申请
export function examReviewRemove(params) {
    return patchRequest("/yethan/examReview/revoke/"+ params);
}
//提交成绩复核
export function examReviewApply(params) {
    return postRequest("/yethan/examReview", params);
}
//成绩复核详情
export function examReviewInfo(params) {
    return getRequest("/yethan/examReview/getInfo/"+params);
}

//证书
// 查询证书列表
export function getCertificateList(params) {
    return getRequest("/yethan/cert/record/listPage", params);
}
// 提交邮寄证书申请
export function applySend(params) {
    return postRequest("/yethan/cert/post", params);
}
//查询申请列表
export function getSendList(params) {
    return getRequest("/yethan/cert/post/listPage", params);
}

//撤销邮寄申请
export function deleteSend(params) {
    return deleteRequest("/yethan/cert/post/"+ params);
}
//缴费
export function payOrder(params) {
    return getRequest("/yethan/pay/trade/cert/order", params);
}
//查询费用
export function certFee(params) {
    return getRequest("/yethan/cert/post/certFee", params);
}
//查询缴费方式
export function payTpye(params) {
    return getRequest("/yethan/cert/post/payType", params);
}

//更新邮寄状态为已签收
export function updateYjzt(params) {
    return putRequest("/yethan/cert/post/"+ params);
}
//检查该证书是否已申请邮寄
export function checkApply(params) {
    return getRequest("/yethan/cert/record/hasApply/"+ params);
}

//不用验证是否登录可查询文件
export function getCjImg(params) {
    return getRequest("/yethan/file/downloadScoreBackground/base64/"+ params);
}
